import classes from './ScoreDifference.module.css';

import { Badge, type BadgeProps, Tooltip } from '@mantine/core';
import cx from 'clsx';

interface Props extends BadgeProps {
  score: number;
  comparisonScore: number;
}

export function ScoreDifference({ className, score, comparisonScore, ...props }: Props) {
  if (Math.abs(score - comparisonScore) < 0.03) {
    return null;
  }

  const leading = score > comparisonScore;
  const pointDiff = Math.trunc((score - comparisonScore) * 100);

  return (
    <Tooltip
      label={`Score is ${Math.abs(pointDiff)} points ${leading ? 'above' : 'below'} the blockchain score and so it ${
      leading ? 'increases' : 'decreases'
      } the blockchain health`}
      multiline
      position="bottom"
      w={250}>

      <Badge
        variant="light"
        className={cx(className, classes.badge, {
          [classes['badge-impediment'] ?? '']: !leading,
          [classes['badge-improvement'] ?? '']: leading
        })}
        radius="sm"
        {...props}>

        {leading ? `Leading (+${pointDiff})` : `Lagging (${pointDiff})`}
      </Badge>
    </Tooltip>);

}