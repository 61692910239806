import classes from './index.module.css';

import { Box, type BoxProps, Text, type TextProps, rem } from '@mantine/core';
import cx from 'clsx';

import { NumberFormat } from '~/utils/NumberFormat';

import { Gauge } from './Gauge';
import { ScoreDifference } from './ScoreDifference';

interface Props extends BoxProps {
  score: number;
  zoneScore?: number;
  size?: number;
  thickness?: number;
  labelProps?: TextProps;
}

export function ScoreRing({
  score,
  size = 160,
  thickness = 10,
  zoneScore,
  labelProps = {},
  className,
  ...others
}: Props) {
  return (
    <Box className={cx(classes.root, className)} w={size} mih={size * 0.66} mx="auto" {...others}>
      <Gauge value={score * 100} size={size} thickness={thickness} />
      <Box className={classes.content} top={thickness + 30}>
        <Text
          className={cx(classes.label, labelProps.className)}
          fw={700}
          ff="var(--observatory-font-family-number)"
          size={rem(size * 0.25)}
          {...labelProps}>

          {NumberFormat.score(score)}
        </Text>
        {typeof zoneScore === 'number' && <ScoreDifference score={score} comparisonScore={zoneScore} />}
      </Box>
    </Box>);

}