import classes from './Gauge.module.css';

import { Box, type BoxProps, rem } from '@mantine/core';
import cx from 'clsx';
import { useMemo } from 'react';

interface Props extends BoxProps {
  value: number;
  size: number;
  thickness: number;
}

export function Gauge({ value, size, thickness, className, style, ...others }: Props) {
  const degrees = useMemo(() => {
    const clampedValue = Math.min(Math.max(value, 0), 100);
    return clampedValue / 100 * 180;
  }, [value]);

  return (
    <Box
      className={cx(classes.gauge, className)}
      style={[
      {
        '--gauge-width': rem(size),
        '--gauge-thickness': rem(thickness),
        '--gauge-rotation': `${degrees}deg`
      },
      style]}

      {...others}>

      <div className={classes['gauge-background']} />
      <div className={classes['gauge-progress']} />
    </Box>);

}